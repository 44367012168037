import store from '@/store';
import i18n from '@/i18n';
import orderApi from '@/api/orderApi';
import userLocalStorageApi from '@ngservices_front/api/userLocalStorageApi';
import paymentService from '@/services/payment/paymentService';
import productService from '@/services/product/productService';
import gtagService from '@/services/loggers/gtagService';
import { getProductPriceObject } from '@/utils/productUtils';

const COUPON_ERROR_MESSAGE_IDS = {
  err_not_found: 'order.promoCodeDoesNotExist',
  err_expired_date: 'order.promocodeHasExpired',
  err_expired_count: 'order.promocodeHasExpired',
  err_max_price: 'order.itemLimitExceeded',
  unknown_error: 'app.somethingWentWrong',
};

const setInitialData = () => {
  const { email: orderEmail } = store.state.order;
  const { price } = store.state.product;
  store.commit('order/SET_TOTAL_PRICE', price);
  if (!orderEmail) {
    const userEmail = userLocalStorageApi.getEmail();
    if (userEmail) store.commit('order/SET_ORDER_EMAIL', userEmail);
  }
};

const registerEntityCard = (cardFile) => orderApi.postEntityCard(cardFile);

const buyDataProduct = async ({ product, email, comment, customerType, legalEntityCardId, coupon }) => {
  store.commit('order/SET_ORDER_EMAIL', email);
  userLocalStorageApi.saveEmail(email);

  const orderData = {
    product,
    paymentSystemSuccessUrl: paymentService.getPaymentSuccessUrl(),
    paymentSystemFailUrl: paymentService.getPaymentFailUrl(),
    paymentSystemRedirectUrl: window.location.href,
    email,
    customerType,
    legalEntityCardId,
    comment,
    coupon,
  };
  // "yoomoney", "stripe", "free".
  const { payment_strategy, order_id, price_origin, price_new, paymentSystemUrl } = await orderApi.createOrder(
    orderData,
  );
  const { originPrice, priceWithDiscount } = getProductPriceObject({ lang: i18n.locale, price_origin, price_new });
  if (originPrice) store.commit('order/SET_TOTAL_PRICE', originPrice);
  if (priceWithDiscount) store.commit('order/SET_TOTAL_PRICE_WITH_DISCOUNT', priceWithDiscount);
  const finalPrice = originPrice || priceWithDiscount;

  gtagService.fireEcommerceEvent({
    eventName: 'purchase',
    eventData: {
      value: finalPrice.amount,
      currency: finalPrice.currency.toUpperCase(),
      transaction_id: order_id,
      tax: 0,
      coupon,
      items: [
        gtagService.createEcommerceItem({
          productItem: product,
          price: finalPrice,
        }),
      ],
    },
  });
  return { payment_strategy, order_id, originPrice, priceWithDiscount, paymentSystemUrl };
};

const applyCoupon = async ({ product, coupon }) => {
  const { originPrice, priceWithDiscount, discount, couponStatus } = await productService.getProductPrice({
    ...product,
    coupon,
  });
  if (originPrice) store.commit('order/SET_TOTAL_PRICE', originPrice);
  if (priceWithDiscount) store.commit('order/SET_TOTAL_PRICE_WITH_DISCOUNT', priceWithDiscount);
  if (discount) store.commit('order/SET_DISCOUNT', discount);

  return {
    originPrice,
    priceWithDiscount,
    discount,
    couponStatus,
  };
};

const resetCoupon = () => {
  store.commit('order/SET_TOTAL_PRICE_WITH_DISCOUNT', undefined);
  store.commit('order/SET_DISCOUNT', undefined);
};

const resetTotalPrice = () => {
  store.dispatch('order/resetPrice', undefined);
};

export default {
  // getTotalPrice,
  setInitialData,
  registerEntityCard,
  buyDataProduct,
  applyCoupon,
  resetCoupon,
  resetTotalPrice,
};
export { COUPON_ERROR_MESSAGE_IDS };
