import { transliterate } from 'transliteration';

let sentProductItems = [];

if (!window.dataLayer) throw new Error('dataLayer does not exist');

const clearDataLayer = () => {
  window.dataLayer.push({ ecommerce: null });
};

const isProductItemSent = (productItem) =>
  sentProductItems.some((sentProductItem) => sentProductItem.code === productItem.code);

const createItemName = (localeName) => transliterate(localeName).replaceAll(' ', '-');

// productItem: productFormatted
const createEcommerceItem = ({ productItem, price }) => {
  const { region, id, options } = productItem;
  return {
    item_id: region.code,
    item_name: createItemName(region.name),
    item_category: region.code === 'custom' ? 'custom' : region.country,
    item_category2: region.code,
    item_category3: id,
    // TODO: add method in productServices for getting format string
    item_variant: id === 'dem' ? `${options.format_vector}, ${options.format_raster}` : options.format,
    price: price && price.amount,
    currency: price && price.currency.toUpperCase(),
    quantity: 1,
  };
};
// productListItem: {code, name, country} - Catalog Item
const createEcommerceListItem = (productListItem) => {
  const { code, name, index, country } = productListItem;
  return {
    item_id: code,
    item_name: createItemName(name),
    item_category: country,
    item_category2: code,
    quantity: 1,
    index,
  };
};

const fireEcommerceEvent = ({ eventName, eventData }) => {
  clearDataLayer();
  if (window.dataLayer) window.dataLayer.push({ event: eventName, ecommerce: eventData });
};

const viewItemList = (productItems) => {
  const productItemToSent = productItems.filter((item) => !isProductItemSent(item));
  if (productItemToSent.length > 0) {
    fireEcommerceEvent({
      eventName: 'view_item_list',
      eventData: {
        item_list_id: 'catalog_list',
        item_list_name: 'Catalog list',
        items: productItemToSent.map((productItem) => createEcommerceListItem(productItem)),
      },
    });
    sentProductItems = [...sentProductItems, ...productItemToSent];
  }
};

export default { fireEcommerceEvent, viewItemList, createEcommerceItem };
